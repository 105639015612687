import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from '../../components/mdx-page';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`How does it work?`}</h1>
    <p>{`That's a good question. Instead of tracking the `}<em parentName="p">{`actual`}</em>{` sketch files (or `}<em parentName="p">{`binaries`}</em>{`) in git, Kactus transforms your sketch file into groups of individual text files – one for each layer/page – that are much more suited for `}<inlineCode parentName="p">{`git`}</inlineCode>{` to work with.`}</p>
    <h2>{`Workflow`}</h2>
    <p>{`The basic workflow looks very similar to how many developers use `}<inlineCode parentName="p">{`git`}</inlineCode>{`:`}</p>
    <ol>
      <li parentName="ol">{`Clone your repo locally`}</li>
      <li parentName="ol">{`Make your changes in Sketch`}</li>
      <li parentName="ol">{`Commit your changes`}</li>
      <li parentName="ol">{`Push your changes`}</li>
    </ol>
    <p>{`However, when working with multiple designers, the `}<a parentName="p" {...{
        "href": "https://www.atlassian.com/git/tutorials/comparing-workflows#gitflow-workflow"
      }}>{`git-flow`}</a>{` methodology works very well:`}</p>
    <ol>
      <li parentName="ol">{`Clone your repo (if you're just getting started)`}</li>
      <li parentName="ol">{`Checkout a feature branch (e.g. `}<inlineCode parentName="li">{`feat/background-color`}</inlineCode>{`)`}</li>
      <li parentName="ol">{`Make your changes in Sketch`}</li>
      <li parentName="ol">{`Commit your changes`}</li>
      <li parentName="ol">{`Either:`}
        <ul parentName="li">
          <li parentName="ul">{`Push your changes to your git server for others to review/merge`}</li>
          <li parentName="ul">{`Merge your changes into another branch (master) yourself`}</li>
        </ul>
      </li>
    </ol>
    <h2>{`Committing Changes`}</h2>
    <p>{`When Kactus is running (with your repository open), it watches your sketch files and re-parses them when a change is detected. This parsed (or exploded) view of your sketch file is what git tracks.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/3254314/28254880-df5a388e-6a65-11e7-8b73-8de6fe227927.gif",
        "alt": "commit"
      }}></img></p>
    <hr></hr>
    <h2>{`Merging two parallel branches`}</h2>
    <ol>
      <li parentName="ol">{`Checkout the branch you want to merge `}<em parentName="li">{`into`}</em></li>
      <li parentName="ol">{`Select "Merge into Current Branch" from the "Branch" menu`}</li>
      <li parentName="ol">{`Select the branch that you want to merge `}<em parentName="li">{`from`}</em></li>
    </ol>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/3254314/28254882-e28fb8d0-6a65-11e7-86a5-d766d4303959.gif",
        "alt": "merge"
      }}></img></p>
    <hr></hr>
    <h2>{`Sharing text styles across multiple sketch files`}</h2>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/3254314/28254883-e5f79a92-6a65-11e7-86cc-3cfdc687a454.gif",
        "alt": "sharetextstyle"
      }}></img></p>
    <hr></hr>
    <h2>{`Existing Repos`}</h2>
    <p>{`If your sketch files are already tracked in a git repository, there are few things you need to do to get the most out of Kactus.`}</p>
    <ol>
      <li parentName="ol">{`Open your repo in Kactus to generate the JSON representation of your sketch files`}</li>
      <li parentName="ol">{`Commit the generated JSON directories`}</li>
      <li parentName="ol">{`Untrack sketch files. In most cases, this can be done with git by running: `}<inlineCode parentName="li">{`git rm --cached *.sketch`}</inlineCode></li>
      <li parentName="ol">{`Tell git to `}<a parentName="li" {...{
          "href": "/help/glossary/#ignore"
        }}>{`ignore`}</a>{` sketch files by adding `}<inlineCode parentName="li">{`*.sketch`}</inlineCode>{` to your repo's `}<inlineCode parentName="li">{`.gitignore`}</inlineCode>{` file (create it if it doesn't exist)`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`Note:`}</em>{` When you create a new repository with Kactus, it is automatically configured to `}<a parentName="p" {...{
          "href": "/help/glossary/#ignore"
        }}>{`ignore`}</a>{` sketch files.`}</p>
    </blockquote>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      